import "./App.css";
import Approutes from "./Components/Routes/approutes";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <>
      <Approutes />
    </>
  );
};

export default App;
