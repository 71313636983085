import React from "react";
import sales from "../../../Assets/sectors/sales.png";
const Sales = () => {
  return (
    <>
      <section
        id="taeled-bredcrumb"
        className="taeled-bredcrumb-section position-relative"
        style={{
          backgroundImage: `url(${require("../../../Assets/Socialbanner.png")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize:"cover"

        }}
      >
        <div className="background_overlay"></div>
        <div className="container">
          <div className="bredcrumb-content position-relative">
            <div className="bredcrumb-title text-uppercase headline">
              <h2>Sales, Service & Support</h2>
            </div>
          </div>
        </div>
      </section>

      <section id="service-tab" className="service-tab-section">
        <div className="container">
          <div className="service-tab-content">
            <div className="row">
              <div className="col-lg-6">
                <div className="service-details-img">
                  <img src={sales} alt="Sales, Service & Support" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-tab-wrapper">
                  <div className="section-title headline">
                    <h2>Sales, Service & Support</h2>
                  </div>
                  <div id="tabsContent2" className="tab-content">
                    <div
                      id="value"
                      className="tab-pane fade active show td-faq-body-content"
                    >
                      Turn product innovations into vital client solutions. The
                      backbone of White ino’s success, the account managers,
                      consultants, admins, and analysts in these roles are all
                      dedicated to top-notch client service. Whether negotiating
                      with Fortune 50 companies, solving technical challenges
                      for small businesses, or surfacing product ads in just the
                      right place, we develop new business opportunities while
                      increasing the use of our product offerings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sales;
